<template>
  <ResizeSplitPane
    :class="['editor', {
      'full-screen': !isSplitScreen,
    }]"
    :resizer-border-thickness="2"
    :resizer-thickness="2"
    :size="50"
    allow-resize
    resizer-color="transparent"
    split-to="columns"
    units="percents">
    <template #firstPane>
      <div
        data-cy="editor-side"
        class="height-100">
        <CyAlert
          v-if="isOldVersion"
          class="ma-0 rounded-0"
          data-cy="new-version-alert"
          :content="$sanitizeHtml($t('newVersionDetected'))"
          :button-label="$t('updateToV2')"
          @click="$emit('update-version')"/>
        <CyCodeEditor
          v-model="editorContentEditable"
          show-gutter
          name="input-forms"
          code-lang="yaml"
          @input="$emit('input', $event.trim())"/>
      </div>
    </template>

    <template #secondPane>
      <div
        v-if="isSplitScreen"
        data-cy="preview-side"
        cols="6"
        class="height-100 cy-scrollbars">
        <CyAlert
          class="ma-2"
          theme="error"
          :content="errors"/>

        <v-expansion-panels
          :value="0"
          accordion>
          <v-expansion-panel
            v-for="useCase in forms.use_cases"
            :key="useCase.name"
            class="expansion-panel">
            <v-expansion-panel-header>
              <h3>{{ _.upperFirst(useCase.name) }}</h3>
            </v-expansion-panel-header>
            <v-expansion-panel-content
              :data-index="useCase.name"
              eager>
              <CyWizardStackForms
                v-if="!_.isEmpty(forms)"
                data-cy="forms-preview"
                :forms="useCase"
                :show-side-panel="false"/>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </template>
  </ResizeSplitPane>
</template>

<script>
import CyCodeEditor from '@/components/code-editor.vue'
import CyWizardStackForms from '@/components/wizard/stack-forms.vue'
import 'brace/mode/yaml'

export default {
  name: 'CyFormsFormsEditor',
  components: {
    CyCodeEditor,
    CyWizardStackForms,
  },
  props: {
    value: {
      type: String,
      default: '',
    },
    editorMode: {
      type: String,
      required: true,
      validator: (mode) => ['preview', 'editor'].includes(mode),
    },
    isOldVersion: {
      type: Boolean,
      default: false,
    },
    forms: {
      type: Object,
      default: () => ({}),
    },
    errors: {
      type: Array,
      default: () => [],
    },
  },
  data: ({ value }) => ({
    editorContentEditable: value,
    activePanel: 0,
  }),
  computed: {
    isSplitScreen () {
      return this.editorMode === 'preview'
    },
  },
  watch: {
    value: {
      handler (value) {
        this.editorContentEditable = value
      },
      immediate: true,
    },
    forms: {
      handler () {
        this.activePanel = 0
      },
      deep: true,
      immediate: true,
    },
  },
  i18n: {
    messages: {
      en: {
        newVersionDetected: `<strong>We've detected a new version for this file.</strong>`,
        updateToV2: 'Update to V2',
      },
      es: {
        newVersionDetected: `<strong>Hemos detectado una nueva versión de este file.</strong>`,
        updateToV2: 'Actualizar a V2',
      },
      fr: {
        newVersionDetected: `<strong>Nous avons détecté une nouvelle version pour ce fichier.</strong>`,
        updateToV2: 'Mise à jour vers V2',
      },
    },
  },
}
</script>

<style lang="scss" scoped>
.expansion-panel {
  ::v-deep {
    > .v-expansion-panel-content > .v-expansion-panel-content__wrap {
      padding: 0;
    }

    .stack-forms {
      min-height: 0;
      padding: 0;
    }

    .stack-forms__expansion-panels {
      position: relative;
      overflow: visible;
    }
  }

  &:nth-child(even) {
    background-color: cy-get-color("grey", "light-3") !important;
  }
}

.editor {
  position: relative;
  min-height: 0;

  ::v-deep .columnsres {
    top: 50%;
    height: 100px;
    margin: 0 3px !important;
    transform: translateY(-50%);
    border-right: solid 2px cy-get-color("grey", "light-1") !important;
    border-left: solid 2px cy-get-color("grey", "light-1") !important;

    &:hover {
      border-right: solid 2px cy-get-color("grey") !important;
      border-left: solid 2px cy-get-color("grey") !important;
    }
  }

  &.full-screen ::v-deep {
    > div:first-child {
      width: 100% !important;
    }

    > span,
    > div:last-child {
      width: 0 !important;
    }
  }
}

::v-deep .Resizer { /* stylelint-disable-line selector-class-pattern */
  z-index: 50;
}
</style>
